import {
  FETCH_REGION_ATTRIBUTES_REQUEST,
  FETCH_REGION_ATTRIBUTES_SUCCESS,
  FETCH_REGION_ATTRIBUTES_ERROR,
  UPDATE_REGION_ATTRIBUTES,
} from "./RegionTypes";

const initialState = {
  loading: false,
  RegionAttributesData: {},
  error: ""
};

const RegionAttributesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REGION_ATTRIBUTES_REQUEST:
      return { ...state, loading: true };
    case FETCH_REGION_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        loading: false,
        RegionAttributesData: action.payload,
        error: ""
      };
    case FETCH_REGION_ATTRIBUTES_ERROR:
      return {
        ...state,
        loading: false,
        RegionAttributesData: {},
        error: action.payload
      };
    case UPDATE_REGION_ATTRIBUTES:
      return {
        ...state,
        RegionAttributesData: action.payload,
      };
    default:
        return state;
  }
};

export default RegionAttributesReducer;

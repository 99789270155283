import { combineReducers } from "redux";
import countryReducer from "./country/countryReducer";
import GlobalAttributesReducer from "./GlobalAttributes/GlobalReducer";
import RegionAttributesReducer from "./RegionAttributes/RegionReducer";
import AllRegionAttributesReducer from "./AllRegionAttributes/AllRegionReducer";
import RetailerAttributesReducer from "./RetailerAttributes/RetailerReducer";
import AllRetailerAttributesReducer from "./AllRetailerAttributes/AllRetailerReducer";

const rootReducer = combineReducers({
  country: countryReducer,
  GlobalAttributesData: GlobalAttributesReducer, 
  RegionAttributesData: RegionAttributesReducer, 
  AllRegionAttributesData:AllRegionAttributesReducer,
  RetailerAttributesData: RetailerAttributesReducer, 
  AllRetailerAttributesData: AllRetailerAttributesReducer
});
export default rootReducer;

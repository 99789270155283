import {
  FETCH_GLOBAL_ATTRIBUTES_REQUEST,
  FETCH_GLOBAL_ATTRIBUTES_SUCCESS,
  FETCH_GLOBAL_ATTRIBUTES_ERROR,
  UPDATE_GLOBAL_ATTRIBUTES
} from "./GlobalTypes";

const initialState = {
  loading: false,
  GlobalAttributesData: {},
  error: ""
};

const GlobalAttributesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GLOBAL_ATTRIBUTES_REQUEST:
      return { ...state, loading: true };
    case FETCH_GLOBAL_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        loading: false,
        GlobalAttributesData: action.payload,
        error: ""
      };
    case FETCH_GLOBAL_ATTRIBUTES_ERROR:
      return {
        ...state,
        loading: false,
        GlobalAttributesData: {},
        error: action.payload
      };
    case UPDATE_GLOBAL_ATTRIBUTES:
      return {
        ...state,
        GlobalAttributesData: action.payload,
      };
    default:
        return state;
  }
};

export default GlobalAttributesReducer;

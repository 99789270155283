import {
  FETCH_ALL_RETAILER_ATTRIBUTES_REQUEST,
  FETCH_ALL_RETAILER_ATTRIBUTES_SUCCESS,
  FETCH_ALL_RETAILER_ATTRIBUTES_ERROR,
  UPDATE_ALL_RETAILER_ATTRIBUTES
} from "./AllRetailerTypes";

const initialState = {
  loading: false,
  AllRetailerAttributesData: {},
  error: ""
};

const AllRetailerAttributesReducer = (state = initialState, action) => {
  switch (action.type) {
      case FETCH_ALL_RETAILER_ATTRIBUTES_REQUEST:
      return { ...state, loading: true };
    case FETCH_ALL_RETAILER_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        loading: false,
        AllRetailerAttributesData: action.payload,
        error: ""
      };
    case FETCH_ALL_RETAILER_ATTRIBUTES_ERROR:
      return {
        ...state,
        loading: false,
        AllRetailerAttributesData: {},
        error: action.payload
      };
    case UPDATE_ALL_RETAILER_ATTRIBUTES:
      return {
        ...state,
        AllRetailerAttributesData: action.payload,
      };
    default:
        return state;
  }
};

export default AllRetailerAttributesReducer;

import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import SouthAmericaIcon from '@mui/icons-material/SouthAmerica';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LanguageIcon from '@mui/icons-material/Language';
import StorefrontIcon from '@mui/icons-material/Storefront';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
import LanOutlinedIcon  from '@mui/icons-material/LanOutlined';
import {getCountryCode} from "../../util/Helper.js";
//import config from "../../config";
import axios from "axios";
let flag=""
let domain ="fixed"
//console.log("domain out", domain)
class Sidebar extends Component {

  state = {
    userRole:""
  };
  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({[menuState] : false});
    } else if(Object.keys(this.state).length === 0) {
      this.setState({[menuState] : true});
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({[i]: false});
      });
      this.setState({[menuState] : true});
    }
  }
   getIndividualRole = async () => {
    let userMail = "";
    let userFirstName = "";
    let userLastName = "";
    let profileData = localStorage.getItem("userProfile");
    let userProfile =
      profileData !== undefined && profileData !== null
        ? JSON.parse(profileData)
        : "";
    userMail = userProfile.email;
    userFirstName = userProfile.firstName;
    userLastName = userProfile.lastName;
    // const individualRolesUrl = `${config.favApi}dc/datacatalog/getuserRole/${userMail}/${userFirstName}${userLastName}`;
    const individualRolesUrl = `${process.env.REACT_APP_favApi}dc/datacatalog/getuserRole/${userMail}/${userFirstName}${userLastName}`;

    const response = await axios.get(individualRolesUrl, {
      headers: {
        "X-Country-Code": `${getCountryCode()}`,
      },
    });
    flag=response?.data?.data?.ROLE
     this.setState({userRole: response?.data?.data?.ROLE})
    return flag;
  };

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    // eslint-disable-next-line no-unused-expressions
    this.getIndividualRole()
    domain  = window.location.href.toLowerCase().includes("dashboard") ? "": "fixed"
    //console.log("domain in", domain)
    let userMail = "";
    let userFirstName = "";
    let userLastName = "";
    let profileData = localStorage.getItem("userProfile");
    let userProfile =
      profileData !== undefined && profileData !== null
        ? JSON.parse(profileData)
        : "";

    userMail = userProfile.email;
    userFirstName = userProfile.firstName;
    userLastName = userProfile.lastName;
    // const individualRolesUrl = `${config.favApi}dc/datacatalog/getuserRole/${userMail}/${userFirstName}${userLastName}`;
    const individualRolesUrl = `${process.env.REACT_APP_favApi}dc/datacatalog/getuserRole/${userMail}/${userFirstName}${userLastName}`;
    const response =  axios.get(individualRolesUrl, {
      headers: {
        "X-Country-Code": `${getCountryCode()}`,
      },
    });
    flag=response?.data?.data?.ROLE
     this.setState({userRole: response?.data?.data?.ROLE})
    
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      
      el.addEventListener('mouseover', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
      domain  = window.location.href.toLowerCase().includes("dashboard") ? "": "fixed"
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({[i]: false});
    });

    const dropdownPaths = [
      {path:'/apps', state: 'appsMenuOpen'},
      {path:'/advanced-ui', state: 'advancedUiMenuOpen'},
      {path:'/form-elements', state: 'formElementsMenuOpen'},
      {path:'/tables', state: 'tablesMenuOpen'},
      {path:'/maps', state: 'mapsMenuOpen'},
      {path:'/icons', state: 'iconsMenuOpen'},
      {path:'/charts', state: 'chartsMenuOpen'},
      {path:'/user-pages', state: 'userPagesMenuOpen'},
      {path:'/error-pages', state: 'errorPagesMenuOpen'},
      {path:'/general-pages', state: 'generalPagesMenuOpen'},
      {path:'/ecommerce', state: 'ecommercePagesMenuOpen'},
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({[obj.state] : true})
      }
    }));
 
  }

  render () { 
    // let positionChange = window.location.href.includes("dashboard") ? "" : "fixed"
    //console.log("domain", domain)
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar" style={{position: domain}}>
        <ul className="nav">
          <li className={ this.isPathActive('/Dashboard') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/Dashboard">
            <HomeIcon aria-hidden="true" style={{fontSize: "1.5rem"}}/>
              <span className="menu-title"><Trans>{"\u00A0\u00A0\u00A0"}Home</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/GlobalAttributes') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/GlobalAttributes">
            <LanguageIcon aria-hidden="true" style={{fontSize: "1.5rem"}}/>
              <span className="menu-title"><Trans>{"\u00A0\u00A0\u00A0"}Global Attributes</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/MarketAttributes') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/MarketAttributes">        
              <SouthAmericaIcon aria-hidden="true" style={{fontSize: "1.5rem"}}/>
              <span className="menu-title"><Trans>{"\u00A0\u00A0\u00A0"}Market Attributes</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/RetailerAttributes') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/RetailerAttributes">
            <StorefrontIcon  style={{fontSize: "1.5rem"}} />
              <span className="menu-title"><Trans>{"\u00A0\u00A0\u00A0"}Retailer Attributes</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/LOVs') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/LOVs">
            <ListAltIcon aria-hidden="true" style={{fontSize: "1.5rem"}}/>
              <span className="menu-title"><Trans>{"\u00A0\u00A0\u00A0\u00A0"}List Of Values (LOVs)</Trans></span>
            </Link>

          </li>
          <li className={ this.isPathActive('/Taxonomy') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/Taxonomy">        
              <LanOutlinedIcon  aria-hidden="true" style={{fontSize: "1.5rem"}}/>
              <span className="menu-title"><Trans>{"\u00A0\u00A0\u00A0"}Brand - Sub Brand</Trans></span>
            </Link>
          </li>
          {
                    flag=== "Admin"  ?

         <li className={ this.isPathActive('/error-pages') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.errorPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('errorPagesMenuOpen') } data-toggle="collapse">
            <SettingsIcon aria-hidden="true" style={{fontSize: "1.5rem"}} />
              <span className="menu-title"><Trans>{"\u00A0\u00A0\u00A0"}Settings</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.errorPagesMenuOpen }>
              <ul className="nav flex-column sub-menu"> 
                {/* <li className="nav-item"> <Link className="nav-link" to= '/Settings/Administration' ><Trans>Administration</Trans></Link></li> */}
                <li className="nav-item"> <Link className="nav-link" to= '/Settings/RolePage' ><Trans>User Roles</Trans></Link></li>
                <li className="nav-item"> <Link className="nav-link" to= '/Settings/UpdateMetadatas' ><Trans>Update Metadatas</Trans></Link></li>
                <li className="nav-item"> <Link className="nav-link" to= '/Settings/DataUpdateLogs' ><Trans>Data Update Logs</Trans></Link></li>
                <li className="nav-item"> <Link className="nav-link" to= '/Settings/DataRefreshLogs' ><Trans>Data Refresh Logs</Trans></Link></li>
              </ul>
            </Collapse>
        </li>
:
        <li className={ this.isPathActive('/error-pages') ? 'nav-item active' : 'nav-item' } style={{opacity:"30%"}}>
            <div className={ this.state.errorPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('errorPagesMenuOpen') } data-toggle="collapse">
            <SettingsIcon aria-hidden="true" style={{fontSize: "1.5rem"}} />
              <span className="menu-title"   title="This page is active for Admin only." ><Trans>{"\u00A0\u00A0\u00A0"}Settings</Trans></span>
              <i className="menu-arrow"></i>
            </div>
        </li>
  }
        <li className={ this.isPathActive('/icons') ? 'nav-item active' : 'nav-item' } style={{opacity:"30%"}}>
            <div className={ this.state.iconsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('iconsMenuOpen') } data-toggle="collapse">
            <ConnectWithoutContactIcon aria-hidden="true" style={{fontSize: "1.5rem"}} />
              <span className="menu-title"  title="This page is currently inactive."><Trans>{"\u00A0\u00A0\u00A0"}S2T Mapping</Trans></span>
            </div>
          </li>
        </ul>
      </nav>
    );
  }


}

export default withRouter(Sidebar);

// import _ from "lodash";

export const getCountryCode = () => {
  let countryCode = "de";
  let countryOrigin = window.location.origin;
  if (countryOrigin !== null && countryOrigin.lastIndexOf(".") !== -1) {
    countryCode = countryOrigin.slice(
      countryOrigin.lastIndexOf(".") + 1,
      countryOrigin.length
    );
    if (!countryCode || countryCode === "com") {
      countryCode = "de";
    }
  }
  return countryCode;
};



import {
  FETCH_RETAILER_ATTRIBUTES_REQUEST,
  FETCH_RETAILER_ATTRIBUTES_SUCCESS,
  FETCH_RETAILER_ATTRIBUTES_ERROR,
  UPDATE_RETAILER_ATTRIBUTES,
} from "./RetailerTypes";

const initialState = {
  loading: false,
  RetailerAttributesData: {},
  error: ""
};

const RetailerAttributesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RETAILER_ATTRIBUTES_REQUEST:
      return { ...state, loading: true };
    case FETCH_RETAILER_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        loading: false,
        RetailerAttributesData: action.payload,
        error: ""
      };
    case FETCH_RETAILER_ATTRIBUTES_ERROR:
      return {
        ...state,
        loading: false,
        RetailerAttributesData: {},
        error: action.payload
      };
    case UPDATE_RETAILER_ATTRIBUTES:
      return {
        ...state,
        RetailerAttributesData: action.payload,
      };
    default:
        return state;
  }
};

export default RetailerAttributesReducer;

import {
  FETCH_ALL_REGION_ATTRIBUTES_REQUEST,
  FETCH_ALL_REGION_ATTRIBUTES_SUCCESS,
  FETCH_ALL_REGION_ATTRIBUTES_ERROR,
  UPDATE_ALL_REGION_ATTRIBUTES
} from "./AllRegionTypes";

const initialState = {
  loading: false,
  AllRegionAttributesData: {},
  error: ""
};

const AllRegionAttributesReducer = (state = initialState, action) => {
  switch (action.type) {
      case FETCH_ALL_REGION_ATTRIBUTES_REQUEST:
      return { ...state, loading: true };
    case FETCH_ALL_REGION_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        loading: false,
        AllRegionAttributesData: action.payload,
        error: ""
      };
    case FETCH_ALL_REGION_ATTRIBUTES_ERROR:
      return {
        ...state,
        loading: false,
        AllRegionAttributesData: {},
        error: action.payload
      };
    case UPDATE_ALL_REGION_ATTRIBUTES:
      return {
        ...state,
        AllRegionAttributesData: action.payload,
      };
    default:
        return state;
  }
};

export default AllRegionAttributesReducer;
